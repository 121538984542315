.profile-deimg {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    /* background: rgba(102, 194, 255, 1); */
}

.profile-img-cont {
    text-align: center;
    padding: 10px;
}

.title-label {
    width: 100%;
    position: relative;
    color: #404040;
    font-weight: 700;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
}

.details-text {
    width: 100%;
    padding: 1px 6px;
    position: relative;
    font-size: 14px;
    margin-bottom: 3px;
    font-family: 'Poppins', sans-serif;
    color: rgb(108, 122, 137);
    font-weight: 700;
}

.text-cont {
    margin-top: 5px;
    text-align: center;
}

.member-id-text {
    color: #4d4d4d;
    font-weight: 700;
    font-size: 20px;
    color: rgba(3, 138, 255, 1);
}

.member-role-text {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 17px;
    color: rgb(132, 140, 207);
}

.member-status-text {
    text-transform: capitalize;
    color: rgba(0, 179, 134, 1);
}

.biodata-text {
    padding: 45px 65px;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    color: rgb(108, 122, 137);
}

.biodata-title {
    color: rgba(102, 194, 255, 1);
    font-size: 20px;
    font-weight: 700;
}

.no-contact-data {
    width: 100%;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    color: #ff6666;
    padding: 10px;
}

.profilecontact-card {
    margin: 6px;
    border-radius: 10px;
    flex: 0 0 auto;
}

.profilecontact-type {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    border-radius: 5px 5px 0px 0px;
    color: #ffffff !important;
}

.profilecontact-regular>.profilecontact-type {
    border-bottom: 2px solid rgba(0, 179, 134, 1);
    background-color: rgba(0, 179, 134, 1);
}

.profilecontact-emergency>.profilecontact-type {
    border-bottom: 2px solid rgba(254, 119, 134, 1);
    background-color: rgba(254, 119, 134, 1);
}

.profilecontact-emergency {
    border: 2px solid rgba(254, 119, 134, 1);
}

.profilecontact-regular {
    border: 2px solid rgba(0, 179, 134, 1);
}

.profilecontact-phone {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1px;
    padding: 2px 10px;
}

.profilecontact-email {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2px 10px;
}

.profilecontact-whatsapp {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    padding: 2px 10px;
}

.profilecontact-whatsapp>i {
    color: #00cc99;
}

.profilecontact-phone>i {
    color: #ffa366;
}

.profilecontact-email>i {
    color: #b3b3ff;
}

.profilecontact-card i {
    margin-right: 4px;
    font-weight: 600;
    font-size: 15px;
    padding-left: 5px;
}

.profilecontact-card .label {
    width: 100%;
    font-size: 12px;
    margin-bottom: 0px;
    padding-left: 0px;
    color: #666666;
}

.profile-backgound {
    background-color: #f2f2f2;
}

.addcontactfield .col-md-3 {
    padding: 5px 8px;
}

.addcontactfield {
    border-top: 2.5px solid #f2f2f2;
    margin-top: 8px;
}

.button-right {
    text-align: right;
}

.contact-con .profilecontact-type {
    text-align: left !important;
}

.profilecontact-type>i {
    padding: 0px 6px;
    cursor: pointer;
}

.profilecontact-type>.head-text {
    text-align: center;
    width: calc(100% - 61px);
    display: inline-block;
}

#ContactMember {
    border-left: 2px solid #f2f2f2;
}

.position-reative {
    position: relative;
}

.no-data {
    text-align: center;
    color: #ff6666;
    font-size: 32px !important;
    font-weight: 700 !important;
    font-family: Poppins, sans-serif;
    padding: 30px 40px;
}

.icon-tab .fa-pencil-alt {
    color: rgba(0, 138, 230, 1);
    font-size: 18px;
    margin-right: 10px;
}

.icon-tab .fa-trash-alt {
    color: rgba(254, 119, 134, 1);
    font-size: 18px;
    margin-left: 10px;
}

.icon-tab .fa-envelope {
    color: rgba(0, 138, 230, 1);
    font-size: 20px;
}

@media (max-width: 768px) {
    #ContactMember {
        margin-top: 5px;
        border-left: none !important;
    }

    .contact-con {
        padding: 5px 12px;
    }

    .no-data {
        padding-left: 10px;
        padding-right: 10px;
    }

    .mob-td-minwidth75 {
        min-width: 75px;
        text-align: center;
    }
}