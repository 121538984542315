.full-Container {
    background-color: #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100%);
    min-width: 100%;
    position: absolute;
}

.login-form {
    display: inline-block;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    color: #4f5767;
    width: 30%;
    padding: 30px 45px;
    padding-bottom: 75px;
    background-image: url(../img/wave.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
}

.logo-form img {
    width: 150px;
    padding: 3px;
    margin: auto;
}

.logo-form {
    text-align: center;
    margin-bottom: 10px;
}

.icon-cont {
    flex: 0 0 auto;
    width: 10%;
    font-size: 22px;
    padding-top: 30px;
    color: #008ECC;
}

.input-cont {
    width: calc(90% - 10px);
    flex: 0 0 auto;
    margin-left: 10px;
}

.texttitle-form {
    text-align: center;
    font-size: 26px;
    margin-top: 10px;
    margin-bottom: 8px;
}

.myloginbtn {
    flex: 1 1 auto;
    padding: 5px 15px;
    text-align: center;
    background-size: 200% auto;
    color: #ffffff;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
    font-family: din, sans-serif;
    border: none;
    outline: none;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 65px;
    background-color: #008ECC;
}

.login_error {
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    color: #ff4d4d;
    font-weight: 800;
    margin-bottom: -10px;
    margin-top: 5px;
    padding-left: 10px;
}

.form__group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 8px;
    margin-bottom: 10px;
    width: 100%;
}

.form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #75b5dc;
    outline: 0;
    font-size: 16px;
    color: #00529b;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
    font-weight: 500;
}

.form__field::placeholder {
    color: transparent;
}

.form__field:placeholder-shown~.form__label {
    font-size: 14px;
    cursor: text;
    top: 25px;
}

.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 14px;
    color: #9b9b9b;
    font-weight: 500;
}

.form__field:focus {
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, #75b5dc, #085398);
    border-image-slice: 1;
}

.form__field:focus~.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 14px;
    color: #047bc3;
    font-weight: 700;
}

.form__field:required,
.form__field:invalid {
    box-shadow: none;
}

.form__field:disabled {
    border-bottom: 2px solid #8f9cba;
}

@media (max-width: 768px) {
    .login-form {
        width: 100%;
    }
    .login_error {
        text-align: center;
    }
}