.topnav {
    position: fixed;
    min-height: 55px;
    padding: 5px 10px;
    width: 100%;
    background-color: #ffffff;
    z-index: 20;
    top: 0px;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0px 1px 20px 1.5px rgba(0, 0, 0, 0.31);
}

.mob-nav-toggle {
    position: relative;
    float: left;
    font-size: 32px;
    padding-right: 8px;
    padding-left: 2px;
    cursor: pointer;
    color: #595959;
    display: none;
}

.total-body {
    width: 100%;
    margin-top: 60px;
    min-height: calc(100vh - 60px);
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
}

.left-total-body {
    min-height: 100%;
    width: 15%;
    flex: 0 0 auto;
}

.right-total-body {
    min-height: 100%;
    flex: 0 0 auto;
    width: 85%;
}

.total-body>div {
    flex-shrink: 0;
}

.greetType {
    position: relative;
    float: left;
    font-size: 13px;
    font-weight: 900;
    letter-spacing: 1px;
    padding: 5px;
    color: #8c8c8c;
}

.greetType>span {
    color: #00ace6;
    font-weight: 800;
}

.rightPart {
    position: relative;
    float: right;
    font-weight: 900;
    letter-spacing: 1px;
    color: #8c8c8c;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}

.optionItem {
    flex: 0 0 auto;
    font-size: 23px;
    padding: 0px 10px;
    cursor: pointer;
    margin: auto;
}

.chat-icon>.mark-num {
    background-color: #ff6666;
    margin-top: 2px;
    margin-left: -2px;
}

.notification-icon>.mark-num {
    background-color: #ffbb33;
    margin-top: 2px;
    margin-left: -10px;
}

.mark-num {
    min-height: 11px;
    min-width: 11px;
    position: absolute;
    padding: 2px;
    border-radius: 50%;
    margin-right: 5px;
}

.profileimg {
    width: 50px;
    height: 50px;
    /* background-color: #d9d9d9; */
    border-radius: 50%;
}

.fullmenu {
    background: rgb(0, 138, 230);
    background: linear-gradient(45deg, rgba(0, 138, 230, 1) 37%, rgba(26, 163, 255, 1) 67%, rgba(102, 194, 255, 1) 100%);
    min-height: 100%;
    position: relative;
    width: 100%;
    z-index: 15;
    padding-top: 20px;
}

.sidemenu-itemlist {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    margin-top: 10px;
}

.menu-item {
    color: #ffffff;
    cursor: pointer;
    letter-spacing: 1px;
    font-size: 13px;
    margin: 2px 0;
    padding: 8px 10px 8px 20px;
}

.menu-item i {
    font-size: 15px;
    margin: 0 5px 0 0;
}

.menu-item.selected {
    background: #ffffff;
    border-left: 5px solid #008ae6;
    color: #008ae6;
    font-weight: 700;
    font-size: 14px;
}

.menu-item.selected i {
    font-size: 17px;
}

.logo-menu {
    background-color: #f2f2f2;
    margin-bottom: 5px;
    padding: 15px 30px;
    text-align: center;
    position: fixed;
    bottom: -5px;
    width: 15%;
}

.logo-menu>img {
    height: auto;
    width: 120px;
}

/* Navigation Bar */
.navigation-container {
    width: 100%;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    background: #414e66;
    color: #ffffff;
    font-weight: 800;
}

.nav-item {
    background-color: rgba(0, 138, 230, 1);
    padding: 5px 20px;
    display: inline-block;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-left: -10px;
    z-index: 8;
    position: relative;
}

.nav-item:first-child {
    margin-left: 0px;
    z-index: 10;
    position: relative;
    background-color: rgba(254, 119, 134, 1);
}

.nav-item:last-child {
    background-color: rgba(0, 179, 134, 1);
    z-index: 5;
    position: relative;
}

.fulldetailsbody {
    background-color: #ffffff;
    min-height: calc(100vh - 90px);
    max-height: calc(100vh - 90px);
}

.toolbar-container {
    padding: 10px 10px;
    border-bottom: 2px solid #f2f2f2;
}

.details-table {
    padding: 10px 10px;
    overflow-y: scroll;
    max-height: calc(100vh - 215px);
    min-height: calc(100vh - 215px);
}

.two-table {
    max-height: 195px;
    min-height: 195px;
}

.two-table2 {
    max-height: 210px;
    min-height: 210px;
}

.details-formcontainer {
    padding: 10px 10px;
    max-height: calc(100vh - 195px);
}

.my-button {
    border: 0;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 900;
    height: auto;
    letter-spacing: .6px;
    margin-right: 2px;
    padding: 5px 10px;
    text-align: center;
    width: auto;
    margin-left: 5px;
    margin-right: 5px;
}

.bg-lightgreen:disabled {
    background-color: rgba(0, 153, 115, 0.7);
}

/* Color */

.bg_lightgray {
    background-color: #f2f2f2;
}

.bg_gray {
    background-color: rgba(242, 242, 242, 0.6);
}

.bg-lightgreen {
    background-color: rgba(0, 153, 115, 0.9);
}

.bg-black {
    background-color: #4f585d;
}

.bg-spblue {
    background-color: #2196f3 !important;
}

.bg-green {
    background-color: #66bb6a !important;
}

.bg-lightblue {
    background-color: #64b5f6 !important;
}

.bg-lightred {
    background-color: #e57373 !important;
}

.bg-deeporange {
    background-color: #ff8a65 !important;
}

.bg-skyblue {
    background-color: #4dd0e1 !important;
}

.bg-yellow {
    background-color: #ffaa00 !important;
}

/*Table*/
.table_inner {
    background-color: #fff;
    margin-bottom: 0;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-family: Poppins, sans-serif;
}

.my_head {
    color: #757575;
}

.normal_td {
    border-top: 0;
    padding: 12px 10px;
    line-height: 1.42857143;
    vertical-align: top;
    font-weight: 700 !important;
}

.startwordcapital {
    text-transform: capitalize;
}

.errordata {
    vertical-align: middle;
    text-align: center;
    color: #ff6666;
    font-size: 15px !important;
    font-weight: 700 !important;
}

thead>tr>td {
    cursor: pointer;
}

.my_body>tr>td {
    padding: 10px;
    line-height: 1.42857143;
    vertical-align: top;
    border-bottom: 1px solid #f0f0f0;
    font-size: 13px;
    font-weight: 500;
}

.my_body>tr:last-child>td {
    padding-bottom: 10px;
}

.my_body>tr:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

.icon-tab {
    text-align: center;
}

.icon-tab .fa-eye {
    color: #00b386;
    font-size: 20px;
}

.icon-tab .fa-file-download {
    color: rgba(254, 119, 134, 1);
    font-size: 20px;
}

.table_blue {
    background: #456d9f !important;
    color: #fff !important;
    font-size: 12px;
    font-weight: 700 !important;
    text-align: left;
    padding: 7px !important;
    vertical-align: middle;
    border-bottom: 2px solid #f0f0f0;
}

/*Tab container*/
.tab-container {
    padding: 0px 15px;
}

.tab {
    display: inline-block;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    padding: 8px 12px;
    cursor: pointer;
    font-weight: 800;
    border-bottom: 2.5px solid rgb(189, 195, 199);
    margin: 0px 5px;
    color: rgb(108, 122, 137);
}

.tab.selected {
    color: #008ae6;
    border-bottom: 2.5px solid rgba(102, 194, 255, 1);
}

.tab-details {
    display: none;
    padding: 10px;
}

.tab-details.selected-tab {
    display: block;
}

/*Big panel*/
.big-panel {
    overflow-y: scroll !important;
}

.big-panel::-webkit-scrollbar {
    width: 6px;
}

.big-panel::-webkit-scrollbar-track {
    background-color: #ffffff;
}

.big-panel::-webkit-scrollbar-thumb {
    background: rgba(0, 138, 230, 1);
}

.big-panel::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 138, 230, 1);
}

.details-table::-webkit-scrollbar {
    width: 6px;
}

.details-table::-webkit-scrollbar-track {
    background-color: #ffffff;
}

.details-table::-webkit-scrollbar-thumb {
    background: rgba(0, 138, 230, 1);
}

.details-table::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 138, 230, 1);
}


.small-padding-r {
    padding-right: 2px;
}

.padding-l-med {
    padding-left: 20px;
}

.padding-r-med {
    padding-right: 20px;
}

/*Models*/
.mymodel {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 500;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.2);
}

.modelfade {
    opacity: 0;
    transition: opacity .15s linear;
}

.modelshow {
    display: block;
    opacity: 1;
}

.mymodal-dialog {
    transition: transform .3s ease-out;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    min-height: 100%;
    position: relative;
    width: auto;
    pointer-events: none;
}

.mymodal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 10px;
    outline: 0;
}

.mymodal-header {
    padding: 10px;
    border-bottom: 2px solid #2196f3;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    font-size: 25px;
    background-color: #2196f3;
    font-weight: bold;
    color: #ffffff;
    font-family: 'Dosis', sans-serif;
}

.mymodal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 23px 20px;
    text-align: center;
    font-size: 17x;
    font-family: 'Roboto Condensed', sans-serif;
}

.mymodal-footer {
    align-items: center;
    padding: 10px;
    border-top: 2px solid #2196f3;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: center;
}

.mymodal-footer .my-button {
    min-width: 90px;
}

.mymodel-error .mymodal-footer {
    border-top: 2px solid rgba(229, 115, 115, 1) !important;
}

.mymodel-sucess .mymodal-footer {
    border-top: 2px solid rgba(0, 179, 134, 1) !important;
}

.mymodel-error .mymodal-header {
    background-color: rgba(229, 115, 115, 1) !important;
    border-bottom: 2px solid rgba(229, 115, 115, 1) !important;
}

.mymodel-sucess .mymodal-header {
    background-color: rgba(0, 179, 134, 1) !important;
    border-bottom: 2px solid rgba(0, 179, 134, 1) !important;
}

.input-model {
    text-align: left !important;
    padding: 0px !important;
}

.input-model .input-formgroup {
    font-size: 15px;
    padding-top: 10px;
}

/*Loading Gif*/
.loading-background {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
}

.loading-img {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100px;
    pointer-events: auto;
    outline: 0;
}

.loading-fourdot {
    width: 75px;
}

.loading-dna {
    width: 150px;
}

.loading-cont {
    max-width: 200px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    min-height: 100%;
    position: relative;
    width: auto;
    pointer-events: none;
    justify-content: center !important;
}

.spc-button {
    margin-top: 8px;
}

.popupcontainter {
    position: absolute;
    top: 55px;
    right: 20px;
    width: 180px;
    background: #ffffff;
    border-radius: 5px;
    text-align: center;
    color: #404040;
    -webkit-box-shadow: 0px 0px 30px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 30px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 30px -4px rgba(0, 0, 0, 0.75);
    font-family: 'Lato', sans-serif;
    display: none;
}

.popupbody {
    font-size: 15px;
    padding: 18px 10px;
    font-weight: 700;
}

.popupfooter {
    font-size: 13.5px;
    display: flex;
}

.popupbut {
    width: 50%;
    padding: 5px 8px;
    cursor: pointer;
    background-color: #008ae6;
    color: #ffffff;
    font-weight: 500;
    letter-spacing: 1px;
    font-weight: 700;
}

.popupbut:first-child {
    border-right: 1px solid #ffffff;
}

.popupbut:last-child {
    border-left: 1px solid #ffffff;
}

.popupshow {
    display: block;
}

.popupbody>span {
    font-size: 13.5px;
    color: rgba(0, 179, 134, 1);
    font-weight: 700;
    text-transform: capitalize;
}

.details-tabletitle {
    width: 100%;
    text-align: center;
    padding: 6px;
    font-size: 27px;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
    color: #00b386;
    border-bottom: 2px solid #f2f2f2;
}

.details-title {
    width: 100%;
    text-align: center;
    padding: 6px;
    font-size: 22px;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
    color: #0b56a7;
    border-bottom: 2px solid #f2f2f2;
}

.dashboard-text {
    font-size: 75px;
    text-align: center;
    padding: 60px;
    font-family: 'Dosis', sans-serif;
    font-weight: 800;
    color: #0b56a7;
}

.spc-mob-form {
    border-right: 2px solid #f2f2f2;
}

.rightPart a {
    color: inherit;
}

.card-container {
    justify-content: center;
    padding: 60px 60px;
}

.card-container>div {
    padding: 20px;
}

.card-single {
    text-align: center;
    vertical-align: middle;
    border: 2px solid;
    padding: 30px 15px;
    border-radius: 3px;
}

.card-logo {
    width: 100%;
    text-align: center;
    font-size: 53px;
    padding: 10px;
}

.card-title {
    width: 100%;
    text-align: center;
    font-size: 21px;
    padding: 10px;
    margin-top: 15px;
    color: #000000 !important;
    font-weight: 700;
    font-family: Poppins, sans-serif;
}

.card-button {
    border: 0;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 900;
    height: auto;
    letter-spacing: .6px;
    padding: 5px 20px;
    text-align: center;
    margin-top: 15px;
}

.card-lightgreen {
    border-color: rgba(0, 153, 115, 0.9);
    color: rgba(0, 153, 115, 0.9);
}

.card-lightred {
    border-color: #e57373;
    color: #e57373;
}

.card-lightblue {
    border-color: #64b5f6;
    color: #64b5f6;
}

.card-deeporange {
    border-color: #ff8a65;
    color: #ff8a65;
}

.card-container>.details-title {
    padding: 0px;
    font-size: 35px;
}

.data-container {
    width: 33%;
    margin: 5px;
}

.data-title {
    font-size: 18px;
    font-weight: 800;
    font-family: Poppins, sans-serif;
    padding: 5px 10px;
    background-color: rgba(102, 194, 255, 1);
    color: #ffffff;
}

.data-item {
    font-size: 14px;
    cursor: pointer;
    padding: 5px 20px;
    background-color:rgba(108, 122, 137, 0.7);
    border-bottom: 1px solid #ffffff;
    color: #ffffff;
    font-weight: 800;
    font-family: Poppins, sans-serif;
}

.fulldata {
    border-left: 2px solid #f2f2f2;
    min-height: 100%;
    padding: 30px 10px;
    max-height: calc(100vh - 145px);
}

.sub-data-title {
    color: rgba(102, 194, 255, 1);
    font-size: 18px;
    font-weight: 800;
    font-family: Poppins, sans-serif;
    padding: 5px 10px;
    border-bottom:2px solid rgba(108, 122, 137, 0.7);
}

.container-data {
    font-size: 18px;    
    padding: 5px 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.downloaddata {
    color:rgba(0, 179, 134, 1);
    cursor: pointer;
    font-family: "Roboto Condense", sans-serif;
}

@media (max-width: 768px) {
    .left-total-body {
        position: fixed;
        width: 70%;
        z-index: 100;
        left: -100%;
        transition: .8s;
    }

    .logo-menu {
        position: absolute;
        bottom: 0px;
        width: 100%;
        margin-bottom: 0px;
    }

    .fullmenu {
        min-height: calc(100vh - 60px);
    }

    .right-total-body {
        width: 100%;
        max-width: 100%;
    }

    .toolbar-container {
        padding: 8px 5px;
    }

    .my-button {
        font-size: 12px;
        margin-left: 3px;
        margin-right: 3px;
        padding: 5px 7px;
    }

    .details-table {
        max-width: 100%;
        overflow-y: none;
        padding: 10px 5px;
        min-height: max-content;
        max-height: max-content;
    }

    .details-table::-webkit-scrollbar {
        width: 4px;
    }

    .table_blue {
        font-size: 11px;
    }

    .cell-w768-nodisplay {
        display: none;
    }

    .topnav {
        padding: 5px;
    }

    .tab-container {
        padding: 0px 1px;
        position: relative;
        min-width: max-content;
    }

    .full-mod-tab {
        overflow-x: scroll;
        padding-bottom: 5px;
    }

    .tab {
        padding: 4px 3px;
        font-size: 13px;
        margin: 0px 2px;
    }

    .tab-details {
        padding: 0px;
    }

    .padding-l-med {
        padding-left: 0px;
    }

    .mob-nav-toggle {
        display: block;
    }

    .left-total-body.mob-nav-display {
        left: 0;
        transition: 1s;
    }

    .spc-button {
        font-size: 16px;
    }

    .mymodel {
        position: fixed;
    }

    .popupcontainter {
        right: 5px;
    }

    .details-tabletitle {
        font-size: 21px;
        padding: 3px;
    }

    .two-table {
        max-height: max-content;
        min-height: max-content;
    }

    .details-title {
        font-size: 19px;
        padding: 3px;
    }

    .details-formcontainer {
        max-height: none;
    }

    .dashboard-text {
        font-size: 50px;
        padding: 10px;
        padding-top: 80px;
    }

    .spc-mob-form {
        padding-bottom: 10px;
        border-bottom: 2px solid #f2f2f2;
        margin-bottom: 10px;
        border-right: none;
    }
}