.profile-details {
    flex: 0 0 auto;
    padding: 15px;
    font-size: 17px;
    font-weight: 300;
    font-family: 'Lato', sans-serif;
    letter-spacing: 1px;
    margin-top: 10px;
    width: calc(100% - 250px);
}

.profile-item {
    margin-left: 10px;
    margin-bottom: 2px;
    color: #404040;
    font-size: 14px;
}

.profile-item>label {
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    color: #262626;
}

.fullpofile-image {
    position: relative;
    width: 250px;
    height: auto;
    text-align: center;
    padding: 10px;
    float: left;
}

.fullpofile-image img {
    position: relative;
    width: 205px;
    height: 205px;
    padding: 5px;
    background-color: #fff;
    border-radius: 50%;    
}

@media (max-width: 768px) {
    .fullpofile-image {
        width: 100%;
    }

    .profile-details {
        width: 100%;
        margin-top: 0px;        
    }
    .profile-item>label{
        font-weight: 800;        
    }
}