.input-formtitle {
  width: 100%;
  text-align: center;
  padding: 8px;
  font-size: 27px;
  font-weight: 800;
  font-family: 'Poppins', sans-serif;
  color: #4da6ff;
}


.input-formgroup {
  width: 100%;
  padding: 6px 20px;
  position: relative;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
}

.input-label {
  width: 100%;
  position: relative;
  color: #4d4d4d;
  margin-bottom: 3px;
  font-weight: 700;
  padding-left: 6px;
}

.input-cell {
  width: 100%;
  padding: 3px 7px;
  position: relative;
  outline: none;
  border: 2.5px solid #e6e6e6;
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 13.5px;
}

.input-cell:focus {
  outline: none;
  border: 2.5px solid rgba(26, 163, 255, 1);
}

textarea.input-cell {
  min-height: 140px;
}

/*Slider*/
.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #00994d;
}

input:focus+.slider {
  box-shadow: 0 0 1px #00994d;
}

input:checked+.slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}

.slider.round {
  border-radius: 23px;
}

.slider.round:before {
  border-radius: 50%;
}


.profile-upimg {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  /* background: rgba(102, 194, 255, 1); */
}

.profile-pic-cont {
  text-align: center;
  padding: 10px;
}

.mandetory-cont {
  padding: 15px 8px;
  font-size: 12px;
  text-align: center;
  font-family: Poppins, sans-serif;
  max-height: calc(100vh - 145px);
}

.mandetory-cont .title {
  padding: 5px;
  margin-bottom: 15px;
  font-size: 18px;
  color: #39ac73;
  font-weight: 600;
}

.mandetory-cont .alert {
  padding: 5px 8px;
}

.password_error {
  margin-top: -5px;
  padding: 0 28px;
}

.password_error p {
  color: #ff4d4d;
  font-family: Poppins, sans-serif;
  font-size: 13px;
  font-weight: 800;
  margin-bottom: 0;
}

.resize-none {
  resize: none;
}

.file-upload-cont {
  width: 90%;
  position: relative;
  cursor: pointer;
  padding: 8px;
  border: 3px dashed rgba(102, 194, 255, 1);
  margin: auto;
  margin-top: 20px;
}

.file-upload-cont input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  font-size: 23px;
  height: 100%;
  width: 100%;
  direction: ltr;
  cursor: pointer;
}

.file-label {
  text-align: center;
  padding: 25px;
  color: rgba(102, 194, 255, 1);
  font-weight: 900;
  font-family: Poppins, sans-serif;
}

.file-label i {
  font-size: 45px;
  margin: auto;
  cursor: pointer;
  margin-bottom: 5px;
}

.file-list {
  width: 90%;
  padding: 8px;
  margin: auto;
  font-family: Poppins, sans-serif;
}

.file-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 15px;
}

.file-type {
  text-align: center;
  padding: 2px 5px;
  min-width: 20%;
  max-width: 10%;
  overflow: hidden;
  background-color: rgba(0, 153, 115, 0.9);
  border-top: 2px solid rgba(0, 153, 115, 0.9);
  border-bottom: 2px solid rgba(0, 153, 115, 0.9);
  border-left: 2px solid rgba(0, 153, 115, 0.9);
  color: #ffffff;
}

.file-name {
  padding: 2px 5px;
  min-width: 65%;
  max-width: 65%;
  overflow: hidden;
  border-top: 2px solid #e6e6e6;
  border-bottom: 2px solid #e6e6e6;
}

.file-item .file-remove {
  padding: 2px 5px;
  cursor: pointer;
  text-align: center;
  max-width: 15%;
  min-width: 15%;
  font-size: 16px;
  color: rgba(254, 119, 134, 1);
  border-top: 2px solid #e6e6e6;
  border-bottom: 2px solid #e6e6e6;
  border-right: 2px solid #e6e6e6;
}

.list-view {
  display: flex;
  margin: 0px;
}

.list-view .file-item {
  width: 33%;
  margin: 5px;
  font-size: 14px;
  max-width: 33%;
  color: rgb(108, 122, 137);
}

.file-item .file-upload {
  padding: 2px 5px;
  cursor: pointer;
  text-align: center;
  max-width: 15%;
  min-width: 15%;
  font-size: 15px;
  color: rgba(0, 153, 115, 0.9);
  border-top: 2px solid #e6e6e6;
  border-bottom: 2px solid #e6e6e6;
  border-right: 2px solid #e6e6e6;
}

.input-label-file {
  width: 100%;
  position: relative;
  color: #4d4d4d;
  margin-bottom: 3px;
  font-weight: 700;
  padding-left: 6px;
  font-size: 13px;
}

@media (max-width: 768px) {
  .input-formgroup {
    padding: 4px 8px;
  }

  .mandetory-cont {
    max-height: none;
  }
}